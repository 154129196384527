import React from "react";
import { ActionIcon, Group, Pill, Text } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";

import { QueryClient } from "@tanstack/react-query";
import { Issue, UserRole } from "../../../../utilities/api/jelbi-dashboard-api";
import { humanizedDateTimeFormat } from "../../../../utilities/client/date-format";
import getProblemTagStyle from "../../../../styles/getProblemTagStyle";
import { isRangerOrHigher } from "../../../../utilities/client/roles.util";
import { ReactComponent as UntrackedIcon } from "../../../../assets/icons/bookmark-line.svg";
import { ReactComponent as TrackedIcon } from "../../../../assets/icons/bookmark-solid.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/trash-line.svg";
import {
	QUERY_KEY_ALL_ISSUES,
	QUERY_KEY_ALL_TRACKED_ISSUES,
} from "../../../../utilities/client/query-keys";
import { confirmDeleteInspectionModal } from "./StationInformationIssues.notifications";

const ICON_SIZE = "24px";

export const updateCachedIssues = (queryClient: QueryClient) => {
	queryClient.invalidateQueries({
		queryKey: [QUERY_KEY_ALL_ISSUES],
	});
	queryClient.invalidateQueries({
		queryKey: [QUERY_KEY_ALL_TRACKED_ISSUES],
	});
};

export const getColumConfig = (
	isMobile: boolean,
	stationId: string,
	trackIssue: (issue: Issue) => void,
	userRoles: UserRole[],
	queryClient: QueryClient
) => {
	const columns: DataTableColumn<Issue>[] = [
		{
			accessor: "problem",
			title: "Problem",
			width: 130,
			render: ({ problem }: Issue) => (
				<Pill bg={getProblemTagStyle(problem)} mb={4}>
					{problem}
				</Pill>
			),
		},
	];

	if (!isMobile) {
		columns.push({
			accessor: "description",
			title: "Beschreibung",
			width: 160,
			ellipsis: true,
		});
	}

	columns.push({
		accessor: "date",
		title: "Datum",
		width: isMobile ? 80 : 154,
		render: (issue) => humanizedDateTimeFormat(new Date(issue.createdAt)),
	});

	columns.push({
		accessor: "actions",
		title: (
			<Text ta="center" fw="bold" size="sm">
				Aktion
			</Text>
		),
		render: (issue) => (
			<Group wrap="nowrap" gap={0}>
				<ActionIcon
					variant="transparent"
					onClick={(event) => {
						event.stopPropagation();
						trackIssue(issue);
					}}
				>
					{issue.isTracked ? (
						<TrackedIcon
							style={{
								fill: "var(--mantine-primary-color-6)",
								height: ICON_SIZE,
								width: ICON_SIZE,
							}}
						/>
					) : (
						<UntrackedIcon
							style={{
								fill: "var(--mantine-primary-color-6)",
								height: ICON_SIZE,
								width: ICON_SIZE,
							}}
						/>
					)}
				</ActionIcon>

				{isRangerOrHigher(userRoles) && (
					<ActionIcon
						variant="transparent"
						onClick={(event) => {
							event.stopPropagation();
							confirmDeleteInspectionModal(
								isMobile,
								issue.id,
								stationId,
								queryClient
							);
						}}
					>
						<TrashIcon style={{ fill: "var(--mantine-primary-color-6)" }} />
					</ActionIcon>
				)}
			</Group>
		),
		width: "0%",
	});

	return columns;
};
