import React from "react";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconExclamationCircleFilled } from "@tabler/icons-react";
import { ok } from "@oazapfts/runtime";
import { modals } from "@mantine/modals";
import { Text } from "@mantine/core";
import { QueryClient } from "@tanstack/react-query";
import {
	QUERY_KEY_ALL_ISSUES,
	QUERY_KEY_ALL_TRACKED_ISSUES,
} from "../../../../utilities/client/query-keys";
import { deleteIssue } from "../../../../utilities/api/jelbi-dashboard-api";

const showDeleteIssueSuccessfulNotification = () => {
	notifications.show({
		id: "successDeleteIssue",
		position: "top-center",
		message: `Problemmeldung gelöscht!`,
		color: "green",
		icon: <IconCheck />,
	});
};

const showDeleteIssueFailedNotification = () => {
	notifications.show({
		id: "failDeleteIssue",
		position: "top-center",
		message: `Problemmeldung konnte nicht gelöscht werden!`,
		color: "red",
		icon: <IconExclamationCircleFilled />,
	});
};

const confirmDeleteIssueModal = (
	isMobile: boolean,
	deleteIssueId: string,
	queryClient: QueryClient
) => {
	modals.openConfirmModal({
		title: "Wirklich löschen?",
		children: <Text>Soll die Problemmeldung wirklich gelöscht werden?</Text>,
		labels: { confirm: "Löschen", cancel: "Abbrechen" },
		confirmProps: { color: "red", fullWidth: isMobile },
		cancelProps: { fullWidth: isMobile },
		groupProps: {
			style: { flexDirection: isMobile ? "column-reverse" : "row" },
		},
		onConfirm: () =>
			ok(deleteIssue(deleteIssueId))
				.then(() => {
					showDeleteIssueSuccessfulNotification();
					queryClient.invalidateQueries({
						queryKey: [QUERY_KEY_ALL_ISSUES],
					});
					queryClient.invalidateQueries({
						queryKey: [QUERY_KEY_ALL_TRACKED_ISSUES],
					});
				})
				.catch(showDeleteIssueFailedNotification),
		withCloseButton: false,
	});
};

export default {
	showDeleteIssueSuccessfulNotification,
	showDeleteIssueFailedNotification,
	confirmDeleteIssueModal,
};
