import React, { ReactElement } from "react";
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { isNotEmpty, useForm } from "@mantine/form";
import { Box, Button, Group, LoadingOverlay, InputLabel } from "@mantine/core";
import {
	CommentAdd,
	Comment,
	postComment,
} from "../../../utilities/api/jelbi-dashboard-api";
import CommentComponent from "../../Comment/Comment";
import Error from "../../Error/Error";
import {
	getIssueCommentsQueryKey,
	QUERY_KEY_ALL_ISSUES,
	QUERY_KEY_ALL_TRACKED_ISSUES,
} from "../../../utilities/client/query-keys";
import commentService from "../../../services/commentService";

import DashboardTextarea from "../../DashboardTextarea/DashboardTextarea";
import { IssueUseFormReturnType } from "./utils/IssueComments.issueTypes";
import {
	showSubmitErrorNotification,
	showSubmitSuccessfulNotification,
} from "./utils/IssueComments.notifications";

type CommentsProps = {
	issueId: string;
};

function IssueComments({ issueId }: CommentsProps): ReactElement {
	const form: IssueUseFormReturnType = useForm({
		mode: "uncontrolled",
		validateInputOnChange: true,
		initialValues: { comment: "" },
		validate: {
			comment: isNotEmpty(
				"Der Kommentar darf nicht leer sein und kann maximal 255 Zeichen enthalten"
			),
		},
	});
	const queryClient = useQueryClient();
	const {
		data: comments,
		isPending,
		isError,
	} = useQuery({
		queryKey: getIssueCommentsQueryKey(issueId),
		queryFn: () => commentService.getCommentsForIssue(issueId),
	});

	const updateIssueCommentCache = async (
		cachedIssueId: string,
		comment: Comment
	): Promise<void> => {
		const commentsQueryKey = getIssueCommentsQueryKey(cachedIssueId);
		await queryClient.cancelQueries({ queryKey: commentsQueryKey });
		const commentData = queryClient.getQueryData<Comment[]>(commentsQueryKey);

		if (commentData) {
			const updatedComments = [comment, ...commentData];
			queryClient.setQueryData(commentsQueryKey, updatedComments);

			queryClient.invalidateQueries({
				queryKey: [QUERY_KEY_ALL_ISSUES],
			});
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEY_ALL_TRACKED_ISSUES],
			});
		}
	};

	const addCommentMutation = useMutation({
		mutationFn: async (commentAdd: CommentAdd) => {
			const response = await postComment(issueId, commentAdd);

			if (response.status !== 201) {
				return Promise.reject();
			}

			return Promise.resolve(response.data);
		},
		onSuccess: (comment: Comment) => {
			showSubmitSuccessfulNotification();
			form.reset();
			updateIssueCommentCache(issueId, comment);
		},
		onError: () => showSubmitErrorNotification(),
	});

	const addComment = (values: { comment: string }) => {
		const newComment: CommentAdd = {
			content: values.comment,
		};

		addCommentMutation.mutate(newComment);
	};

	if (isPending) {
		return (
			<Box pos="relative" h={200} bg="gray.2">
				<LoadingOverlay visible />
			</Box>
		);
	}

	if (isError) {
		return <Error />;
	}

	return (
		<>
			<form
				onSubmit={form.onSubmit((values) => {
					addComment({ comment: values.comment });
				})}
			>
				<InputLabel>Kommentare</InputLabel>
				<DashboardTextarea
					key={form.key("comment")}
					formInputProps={form.getInputProps("comment")}
					label=""
					placeholder="Neuen Kommentar hinzufügen..."
					withAsterisk={false}
				/>
				<Group justify="flex-end" mt={24}>
					<Button
						type="submit"
						variant="outline"
						loading={addCommentMutation.isPending}
					>
						Kommentieren
					</Button>
				</Group>
			</form>

			<Box mt={24}>
				{comments.map(({ createdAt, content, id }) => (
					<CommentComponent
						date={new Date(createdAt)}
						comment={content}
						key={id}
					/>
				))}
			</Box>
		</>
	);
}

export default IssueComments;
