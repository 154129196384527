import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { Group, Stack, Title, Button, SimpleGrid } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import InspectionsTable from "./InspectionsTable/InspectionsTable";
import classes from "./Inspections.module.scss";

function Inspections(): ReactElement {
	return (
		<Stack className={classes.container}>
			<SimpleGrid className={classes.header}>
				<Title order={3}>Begehungen</Title>
				<Group justify="flex-end">
					<Link to="add">
						<Button leftSection={<IconPlus />}>Neue Begehung</Button>
					</Link>
				</Group>
			</SimpleGrid>

			<InspectionsTable />
		</Stack>
	);
}

export default Inspections;
