import React, { ReactElement, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
	Button,
	Grid,
	Group,
	InputLabel,
	Stack,
	Title,
	Text,
	LoadingOverlay,
	Box,
	Pill,
} from "@mantine/core";
import { ok } from "@oazapfts/runtime";
import Lightbox, { SlideImage } from "yet-another-react-lightbox";
import { useDisclosure } from "@mantine/hooks";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Download from "yet-another-react-lightbox/plugins/download";
import IssueComment from "../IssueComments/IssueComments";
import { getIssue, getPhoto } from "../../../utilities/api/jelbi-dashboard-api";
import useIsMobileView from "../../../utilities/client/hooks/useIsMobileView";
import classes from "./ReadIssue.module.scss";
import { humanizedDateTimeFormat } from "../../../utilities/client/date-format";
import {
	getIssueQueryKey,
	getPhotosQueryKey,
} from "../../../utilities/client/query-keys";
import ImageWithOverlay from "../../ImageWithOverlay/ImageWithOverlay";
import Error from "../../Error/Error";
import getProblemTagStyle from "../../../styles/getProblemTagStyle";

const IMAGE_SIZE = 100;

type ReadIssueProps = {
	issueId: string;
	onClose: () => void;
};

function ReadIssue({ issueId, onClose }: ReadIssueProps): ReactElement {
	const isMobile = useIsMobileView();
	const [isImagePreviewerOpen, imagePreviewerHandler] = useDisclosure(false);
	const [selectedImageId, setSelectedImageId] = useState(0);

	const { data: issue, isPending } = useQuery({
		queryKey: getIssueQueryKey(issueId || ""),
		queryFn: () => ok(getIssue(issueId || "")),
		// The query will not execute until routeMatch.params.mspId
		enabled: !!issueId,
	});

	const photoIds = issue?.photos || [];
	const getPhotos = useQuery({
		queryKey: [getPhotosQueryKey(issueId || "")],
		queryFn: async () =>
			// eslint-disable-next-line no-return-await
			Promise.all(photoIds.map(async (photo) => await getPhoto(photo.id))),
		// The query will not execute until routeMatch.params.mspId
		enabled: !!issue,
	});

	let photos: JSX.Element[] = [];
	const slidePhotos: SlideImage[] = [];

	if (getPhotos.isSuccess) {
		const photosData = getPhotos.data.map((response) => response.data);
		photos = photosData.map((photoData, i) => {
			let photoUri = "";

			if (typeof photoData === "string") {
				photoUri = `data:image/jpeg;base64,${photoData}`;
				slidePhotos.push({
					src: photoUri,
					download: {
						url: "",
						filename: `${issueId}-${i}.jpeg`,
					},
				});
			}

			return (
				<ImageWithOverlay
					key={crypto.randomUUID()}
					src={photoUri}
					onClickHandler={() => {
						if (photoUri !== "") {
							imagePreviewerHandler.open();
							setSelectedImageId(i);
						}
					}}
					size={IMAGE_SIZE}
				/>
			);
		});
	} else {
		photos = photoIds.map(() => (
			<Box
				pos="relative"
				h={IMAGE_SIZE}
				w={IMAGE_SIZE}
				key={crypto.randomUUID()}
			>
				<LoadingOverlay visible />
			</Box>
		));
	}

	if (isPending) {
		return <LoadingOverlay visible />;
	}

	if (!issue) {
		return <Error />;
	}

	return (
		<Stack className={classes.container} mt={90}>
			<Title order={3}>Problemmeldung</Title>
			<Grid gutter={{ base: 28, lg: 60 }}>
				<Grid.Col span={{ base: 12, lg: 6 }}>
					<InputLabel>Standort</InputLabel>
					<Text>{issue.station.name}</Text>
				</Grid.Col>
				<Grid.Col span={{ base: 12, lg: 6 }}>
					<InputLabel>Datum</InputLabel>
					<Text>{humanizedDateTimeFormat(new Date(issue.createdAt))}</Text>
				</Grid.Col>
				<Grid.Col span={{ base: 12, lg: 6 }}>
					<InputLabel>Ausstattung</InputLabel>
					<Text>{issue.equipment}</Text>
				</Grid.Col>
				<Grid.Col span={{ base: 12, lg: 6 }}>
					<InputLabel>Problem</InputLabel>
					<Text>
						<Pill bg={getProblemTagStyle(issue.problem)} mb={4} size="md">
							{issue.problem}
						</Pill>
					</Text>
				</Grid.Col>
				<Grid.Col span={{ base: 12, lg: 6 }}>
					<InputLabel>Beschreibung</InputLabel>
					<Text>{issue.description}</Text>
				</Grid.Col>
				{photos.length ? (
					<Grid.Col span={12}>
						<InputLabel>Fotos</InputLabel>
						<Group>{photos}</Group>
					</Grid.Col>
				) : (
					""
				)}
				<Grid.Col span={12}>
					<IssueComment issueId={issue.id} />
				</Grid.Col>
				<Grid.Col span={12} pt={0}>
					<Group
						justify="flex-end"
						style={{ flexDirection: isMobile ? "column-reverse" : "row" }}
					>
						<Button variant="primary" fullWidth={isMobile} onClick={onClose}>
							Schließen
						</Button>
					</Group>
				</Grid.Col>
			</Grid>
			<Lightbox
				open={isImagePreviewerOpen}
				close={imagePreviewerHandler.close}
				slides={slidePhotos}
				plugins={[Zoom, Download]}
				index={selectedImageId}
				zoom={{
					scrollToZoom: true,
					maxZoomPixelRatio: 3,
				}}
				controller={{ closeOnBackdropClick: true }}
			/>
		</Stack>
	);
}

export default ReadIssue;
