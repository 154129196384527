import React, { ReactElement } from "react";
import { Divider, Text } from "@mantine/core";
import { humanizedDateTimeLong } from "../../utilities/client/date-format";

type CommentsProps = {
	date: Date;
	comment: string;
};

function Comment({ date, comment }: CommentsProps): ReactElement {
	return (
		<>
			<Divider labelPosition="right" label={humanizedDateTimeLong(date)} />
			<Text component="p" size="sm" my={14}>
				{comment}
			</Text>
		</>
	);
}

export default Comment;
