import React from "react";
import {
	ActionIcon,
	Checkbox,
	ComboboxData,
	Group,
	MultiSelect,
	Pill,
	Stack,
	Text,
} from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import { DatePicker, DateValue } from "@mantine/dates";
import { IconSearch } from "@tabler/icons-react";
import { QueryClient } from "@tanstack/react-query";
import { endOfDay, isEqual, parseISO } from "date-fns";
import { Issue, UserRole } from "../../../../utilities/api/jelbi-dashboard-api";
import tableNotification from "./IssuesTable.notifications";
import { humanizedDateTimeFormat } from "../../../../utilities/client/date-format";
import getProblemTagStyle from "../../../../styles/getProblemTagStyle";
import { isRangerOrHigher } from "../../../../utilities/client/roles.util";
import { ReactComponent as ConversationIcon } from "../../../../assets/icons/comment-conversation-line.svg";
import { ReactComponent as CommentIcon } from "../../../../assets/icons/comment-text-line.svg";
import { ReactComponent as AttachmentIcon } from "../../../../assets/icons/paperclip-line.svg";
import { ReactComponent as ImageIcon } from "../../../../assets/icons/image-line.svg";
import { ReactComponent as UntrackedIcon } from "../../../../assets/icons/bookmark-line.svg";
import { ReactComponent as TrackedIcon } from "../../../../assets/icons/bookmark-solid.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/trash-line.svg";
import {
	QUERY_KEY_ALL_ISSUES,
	QUERY_KEY_ALL_TRACKED_ISSUES,
} from "../../../../utilities/client/query-keys";

const ICON_SIZE = "24px";
const ICON_ROW_COLOR = "var(--mantine-color-gray-7)";

export const filterRecords = (
	issues: Issue[],
	stationFilterValue: string[],
	problemFilterValue: string[],
	dateFilterValue: DateValue | undefined
) =>
	issues.filter((issue) => {
		if (
			stationFilterValue.length > 0 &&
			!stationFilterValue.some(
				(stationFilterKey) => issue.station.name === stationFilterKey
			)
		) {
			return false;
		}

		if (
			dateFilterValue &&
			!isEqual(endOfDay(parseISO(issue.createdAt)), endOfDay(dateFilterValue))
		) {
			return false;
		}

		if (
			problemFilterValue.length > 0 &&
			!problemFilterValue.some(
				(problemFilterKey) => issue.problem === problemFilterKey
			)
		) {
			return false;
		}

		return true;
	});

export const updateCachedIssues = (queryClient: QueryClient) => {
	queryClient.invalidateQueries({
		queryKey: [QUERY_KEY_ALL_ISSUES],
	});
	queryClient.invalidateQueries({
		queryKey: [QUERY_KEY_ALL_TRACKED_ISSUES],
	});
};

export const getColumConfig = (
	isMobile: boolean,
	stationForSelectData: ComboboxData,
	problemFilterValue: string[],
	setProblemFilterValue: React.Dispatch<React.SetStateAction<string[]>>,
	stationFilterValue: string[],
	setStationFilterValue: React.Dispatch<React.SetStateAction<string[]>>,
	dateFilterValue: DateValue | undefined,
	setDateFilterValue: React.Dispatch<
		React.SetStateAction<DateValue | undefined>
	>,
	queryClient: QueryClient,
	userRoles: UserRole[],
	issues: Issue[],
	trackIssue: (issue: Issue) => void
) => {
	const stationFilter = (
		<MultiSelect
			maw="256px"
			label="Standorte"
			data={stationForSelectData}
			value={stationFilterValue}
			placeholder="Standort suchen"
			onChange={setStationFilterValue}
			leftSection={<IconSearch size={16} />}
			clearable
			searchable
			comboboxProps={{ withinPortal: false }}
		/>
	);

	const dateFilter = (
		<DatePicker
			maxDate={new Date()}
			value={dateFilterValue}
			allowDeselect
			onChange={setDateFilterValue}
		/>
	);

	const problemFilter = (
		<Checkbox.Group
			maw="256px"
			label="Problem"
			value={problemFilterValue}
			onChange={setProblemFilterValue}
		>
			<Stack>
				{[...new Set(issues.map((issue) => issue.problem))].map((problem) => (
					<Checkbox value={problem} label={problem} key={problem} />
				))}
			</Stack>
		</Checkbox.Group>
	);

	const columns: DataTableColumn<Issue>[] = isMobile
		? [
				{
					accessor: "station.name+issues",
					title: "Problem/Standort",
					render: ({ station, problem }: Issue) => {
						return (
							<>
								<Pill bg={getProblemTagStyle(problem)} mb={6} my={8}>
									<Text size="xs" component="span">
										{problem}
									</Text>
								</Pill>
								<Text size="sm">{station.name}</Text>
							</>
						);
					},
					filter: (
						<Stack>
							{problemFilter}
							{stationFilter}
						</Stack>
					),
					filtering:
						stationFilterValue.length > 0 || problemFilterValue.length > 0,
				},
			]
		: [
				{
					accessor: "problem",
					title: "Problem",
					filter: problemFilter,
					filtering: problemFilterValue.length > 0,
					width: 150,
					render: ({ problem }: Issue) => (
						<Pill bg={getProblemTagStyle(problem)} mb={6}>
							{problem}
						</Pill>
					),
				},
				{
					accessor: "description",
					title: "Beschreibung",
					width: 300,
					ellipsis: true,
				},
				{
					accessor: "comments",
					title: (
						<ConversationIcon
							style={{
								height: ICON_SIZE,
								width: ICON_SIZE,
							}}
						/>
					),
					render: ({ comments }: Issue) =>
						comments.length ? (
							<CommentIcon
								style={{
									height: ICON_SIZE,
									width: ICON_SIZE,
									fill: ICON_ROW_COLOR,
								}}
							/>
						) : (
							""
						),
				},
				{
					accessor: "photos",
					title: (
						<AttachmentIcon
							style={{
								height: ICON_SIZE,
								width: ICON_SIZE,
							}}
						/>
					),
					render: ({ photos }: Issue) =>
						photos.length ? (
							<ImageIcon
								style={{
									height: ICON_SIZE,
									width: ICON_SIZE,
									fill: ICON_ROW_COLOR,
								}}
							/>
						) : (
							""
						),
				},
				{
					accessor: "station.name",
					title: "Standtort",
					filter: stationFilter,
					filtering: stationFilterValue.length > 0,
				},
			];

	columns.push({
		accessor: "date",
		title: "Datum",
		render: (issue) => humanizedDateTimeFormat(new Date(issue.createdAt)),
		filter: dateFilter,
		filtering: Boolean(dateFilterValue),
	});

	columns.push({
		accessor: "actions",
		title: (
			<Text ta="center" fw="bold" size="sm">
				Aktion
			</Text>
		),
		render: (issue) => (
			<Group wrap="nowrap" gap={0}>
				<ActionIcon
					variant="transparent"
					onClick={(event) => {
						event.stopPropagation();
						trackIssue(issue);
					}}
				>
					{issue.isTracked ? (
						<TrackedIcon
							style={{
								fill: "var(--mantine-primary-color-6)",
								height: ICON_SIZE,
								width: ICON_SIZE,
							}}
						/>
					) : (
						<UntrackedIcon
							style={{
								fill: "var(--mantine-primary-color-6)",
								height: ICON_SIZE,
								width: ICON_SIZE,
							}}
						/>
					)}
				</ActionIcon>

				{isRangerOrHigher(userRoles) && (
					<ActionIcon
						variant="transparent"
						onClick={(event) => {
							event.stopPropagation();
							tableNotification.confirmDeleteIssueModal(
								isMobile,
								issue.id,
								queryClient
							);
						}}
					>
						<TrashIcon style={{ fill: "var(--mantine-primary-color-6)" }} />
					</ActionIcon>
				)}
			</Group>
		),
		width: "0%",
	});

	return columns;
};
