import React from "react";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconExclamationCircleFilled } from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import { QueryClient } from "@tanstack/react-query";
import { Text } from "@mantine/core";
import { ok } from "@oazapfts/runtime";
import { QUERY_KEY_ALL_INSPECTIONS } from "../../../../utilities/client/query-keys";
import { deleteInspection } from "../../../../utilities/api/jelbi-dashboard-api";

const showDeleteInspectionSuccessfulNotification = () => {
	notifications.show({
		id: "successDeleteInspection",
		position: "top-center",
		message: `Begehung gelöscht!`,
		color: "green",
		icon: <IconCheck />,
	});
};

const showDeleteInspectionFailedNotification = () => {
	notifications.show({
		id: "failDeleteInspection",
		position: "top-center",
		message: `Begehung konnte nicht gelöscht werden!`,
		color: "red",
		icon: <IconExclamationCircleFilled />,
	});
};

export const confirmDeleteInspection = (
	isMobile: boolean,
	deleteInspectionId: string,
	queryClient: QueryClient
) => {
	modals.openConfirmModal({
		title: "Wirklich löschen?",
		children: <Text>Soll die Begehung wirklich gelöscht werden?</Text>,
		labels: { confirm: "Löschen", cancel: "Abbrechen" },
		confirmProps: { color: "red", fullWidth: isMobile },
		cancelProps: { fullWidth: isMobile },
		groupProps: {
			style: { flexDirection: isMobile ? "column-reverse" : "row" },
		},
		onConfirm: () =>
			ok(deleteInspection(deleteInspectionId))
				.then(() => {
					showDeleteInspectionSuccessfulNotification();
					queryClient.invalidateQueries({
						queryKey: [QUERY_KEY_ALL_INSPECTIONS],
					});
				})
				.catch(showDeleteInspectionFailedNotification),
		withCloseButton: false,
	});
};

export default {
	showDeleteInspectionSuccessfulNotification,
	showDeleteInspectionFailedNotification,
	confirmDeleteInspection,
};
