import React, { ReactElement, useEffect, useState } from "react";
import {
	QueryClient,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import { subYears } from "date-fns";
import { useDisclosure } from "@mantine/hooks";
import { Box, LoadingOverlay, Modal } from "@mantine/core";
import issuesService from "../../../services/issuesService";
import Error from "../../Error/Error";
import { getColumConfig } from "./utils/StationInformationIssues.tableHelper";
import { Id, Issue } from "../../../utilities/api/jelbi-dashboard-api";
import useIsMobileView from "../../../utilities/client/hooks/useIsMobileView";
import {
	QUERY_KEY_STATION_ISSUES,
	QUERY_KEY_STATION_TRACKED_ISSUES,
} from "../../../utilities/client/query-keys";
import useGetUserRoles from "../../../utilities/client/hooks/useGetUserRoles";
import Table from "../../Table/Table";
import ReadIssue from "../../Issues/ReadIssue/ReadIssue";

type StationInformationIssuesProps = {
	stationId: Id;
};

const updateCachedIssues = (queryClient: QueryClient, stationId: string) => {
	queryClient.invalidateQueries({
		queryKey: [QUERY_KEY_STATION_ISSUES, stationId],
	});
	queryClient.invalidateQueries({
		queryKey: [QUERY_KEY_STATION_TRACKED_ISSUES, stationId],
	});
};

function StationInformationIssues({
	stationId,
}: StationInformationIssuesProps): ReactElement {
	const isMobile = useIsMobileView();
	const queryClient = useQueryClient();
	const userRoles = useGetUserRoles();
	const [shownIssueId, setShownIssueId] = useState<string>();
	const [records, setRecords] = useState<Issue[]>([]);
	const queryEndDate = new Date().toISOString();
	const queryStartDate = subYears(queryEndDate, 1).toISOString();
	const [page, setPage] = useState<number>(1);

	const [isIssueModalShown, { open: showIssueModal, close: closeIssueModal }] =
		useDisclosure(false);

	const {
		data: issues,
		isPending,
		isError,
	} = useQuery({
		queryKey: [QUERY_KEY_STATION_ISSUES, stationId],
		queryFn: () => {
			return issuesService.fetchIssues({
				start: queryStartDate,
				end: queryEndDate,
				stationIds: [stationId],
			});
		},
	});

	useEffect(() => {
		if (issues) {
			setRecords(issues.results);
		}
	}, [issues]);

	const trackingMutation = useMutation({
		mutationFn: (issue: Issue) => {
			return issuesService.editIssue(issue.id, !issue.isTracked);
		},
		onSuccess: () => {
			updateCachedIssues(queryClient, stationId);
		},
	});

	const trackIssue = (issue: Issue) => {
		trackingMutation.mutate(issue);
	};

	const columns = getColumConfig(
		isMobile,
		stationId,
		trackIssue,
		userRoles,
		queryClient
	);

	if (isPending) {
		return (
			<Box pos="relative" h={200} bg="gray.2">
				<LoadingOverlay visible />
			</Box>
		);
	}

	if (isError) {
		return <Error />;
	}

	return (
		<>
			<Table<Issue>
				columns={columns}
				records={records}
				onRowClick={({ record: issue }) => {
					setShownIssueId(issue.id);
					showIssueModal();
				}}
				recordsPerPage={5}
				page={page}
				setPage={setPage}
			/>
			{shownIssueId && (
				<Modal
					fullScreen
					withCloseButton={false}
					opened={isIssueModalShown}
					onClose={() => {
						closeIssueModal();
					}}
				>
					<ReadIssue
						issueId={shownIssueId}
						onClose={() => {
							closeIssueModal();
						}}
					/>
				</Modal>
			)}
		</>
	);
}

export default StationInformationIssues;
