import {
	Group,
	LoadingOverlay,
	Stack,
	Title,
	Button,
	SimpleGrid,
} from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { ok } from "@oazapfts/runtime";
import { DataTableColumn } from "mantine-datatable";
import React, { ReactElement, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { IconPlus } from "@tabler/icons-react";
import { getMsps, Msp } from "../../utilities/api/jelbi-dashboard-api";
import { QUERY_KEY_ALL_MSPS } from "../../utilities/client/query-keys";
import Error from "../Error/Error";
import Table from "../Table/Table";
import useMaxAvailableMicromobilityQuota from "./MspAddEditForm/useMaxAvailableMicromobilityQuota";
import classes from "./Msps.module.scss";

function Msps(): ReactElement {
	const { PUBLIC_URL } = process.env;
	const navigate = useNavigate();
	const availableMicromobilityQuota = useMaxAvailableMicromobilityQuota();
	const [usedMicromobilityQuota, setUsedMicromobilityQuota] =
		useState<number>(100);
	const [page, setPage] = useState<number>(1);

	useEffect(() => {
		setUsedMicromobilityQuota(100 - availableMicromobilityQuota);
	}, [availableMicromobilityQuota]);

	const {
		data: msps,
		isPending,
		isError,
	} = useQuery({
		queryKey: [QUERY_KEY_ALL_MSPS],
		queryFn: () => ok(getMsps()),
	});

	const columns: DataTableColumn<Msp>[] = [
		{
			accessor: "msp.name",
			title: "Name",
			render: (msp) => msp.name,
		},
		{
			accessor: "msp.image",
			title: "Logo",
			render: (msp) => {
				if (msp.imageId) {
					return (
						<img
							className={classes["msp-logo"]}
							src={PUBLIC_URL + msp.imageId}
							alt={msp.name}
							title={msp.name}
						/>
					);
				}
				return "";
			},
			textAlign: "center",
		},
		{
			accessor: "msp.email",
			title: "Email",
			render: (msp) => msp.email.join(", "),
		},
		{
			accessor: "contingent",
			title: `Kontingent Mikromobilität (∑ ${usedMicromobilityQuota}%)`,
			render: (msp) => `${Math.trunc(msp.micromobilityQuotaPercentage * 100)}%`,
			textAlign: "center",
		},
		{
			accessor: "msp.distributionsMspId",
			title: "Trafi-Id",
			render: (msp) => msp.distributionsMspId,
		},
	];

	if (isPending) {
		return <LoadingOverlay visible />;
	}

	if (isError) {
		return <Error />;
	}

	return (
		<Stack className={classes.container}>
			<SimpleGrid>
				<Title order={3}>MSPs</Title>
				<Group justify="flex-end">
					<Link to="add">
						<Button leftSection={<IconPlus />}>MSP hinzufügen</Button>
					</Link>
				</Group>
			</SimpleGrid>
			<Table<Msp>
				columns={columns}
				records={msps.sort((mspA, mspB) => mspA.name.localeCompare(mspB.name))}
				onRowClick={({ record }) => navigate(`/msps/${record.id}/edit`)}
				page={page}
				setPage={setPage}
				recordsPerPage={11}
			/>
		</Stack>
	);
}

export default Msps;
