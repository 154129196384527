import React from "react";
import { DatePicker, DateValue } from "@mantine/dates";
import { endOfDay, isEqual, parseISO } from "date-fns";
import { DataTableColumn } from "mantine-datatable";
import {
	ActionIcon,
	Box,
	Checkbox,
	ComboboxData,
	MultiSelect,
	Pill,
	Stack,
	Text,
} from "@mantine/core";
import { QueryClient } from "@tanstack/react-query";
import { IconSearch, IconTrashX } from "@tabler/icons-react";
import {
	Inspection,
	UserRole,
} from "../../../../utilities/api/jelbi-dashboard-api";
import { humanizedDateTimeFormat } from "../../../../utilities/client/date-format";
import { isRangerOrHigher } from "../../../../utilities/client/roles.util";
import { confirmDeleteInspection } from "./InspectionsTable.notifications";

export const filterRecords = (
	inspections: Inspection[],
	stationFilterValue: string[],
	problemFilterValue: string[],
	dateFilterValue: DateValue | undefined
) =>
	inspections.filter((inspection) => {
		if (
			stationFilterValue.length > 0 &&
			!stationFilterValue.some(
				(stationFilterKey) => inspection.station.name === stationFilterKey
			)
		) {
			return false;
		}

		if (
			dateFilterValue &&
			!isEqual(
				endOfDay(parseISO(inspection.createdAt)),
				endOfDay(dateFilterValue)
			)
		) {
			return false;
		}

		if (problemFilterValue.length === 2) {
			// both options selected
			return true;
		}
		if (problemFilterValue.includes("WITH")) {
			return inspection.issues.length > 0;
		}
		if (problemFilterValue.includes("WITHOUT")) {
			return inspection.issues.length === 0;
		}

		return true;
	});

export const getColumnConfig = (
	isMobile: boolean,
	stationForSelectData: ComboboxData,
	problemFilterValue: string[],
	setProblemFilterValue: React.Dispatch<React.SetStateAction<string[]>>,
	stationFilterValue: string[],
	setStationFilterValue: React.Dispatch<React.SetStateAction<string[]>>,
	dateFilterValue: DateValue | undefined,
	setDateFilterValue: React.Dispatch<
		React.SetStateAction<DateValue | undefined>
	>,
	queryClient: QueryClient,
	userRoles: UserRole[]
) => {
	const stationFilter = (
		<MultiSelect
			maw="256px"
			label="Standorte"
			data={stationForSelectData}
			value={stationFilterValue}
			placeholder="Standort suchen"
			onChange={setStationFilterValue}
			leftSection={<IconSearch size={16} />}
			clearable
			searchable
			comboboxProps={{ withinPortal: false }}
		/>
	);

	const dateFilter = (
		<DatePicker
			maxDate={new Date()}
			value={dateFilterValue}
			allowDeselect
			onChange={setDateFilterValue}
		/>
	);

	const problemFilter = (
		<Checkbox.Group
			maw="256px"
			label="Probleme"
			value={problemFilterValue}
			onChange={setProblemFilterValue}
		>
			<Stack>
				<Checkbox value="WITHOUT" label="Begehungen ohne Problemmeldungen" />
				<Checkbox value="WITH" label="Begehungen mit Problemmeldungen" />
			</Stack>
		</Checkbox.Group>
	);

	const columns: DataTableColumn<Inspection>[] = isMobile
		? [
				{
					accessor: "station.name+issues",
					title: "Standort/Probleme",
					render: (inspection: Inspection) => {
						const problemCount = inspection.issues.length;
						const tagText =
							problemCount + (problemCount === 1 ? " Problem" : " Probleme");

						return (
							<>
								{problemCount > 0 && (
									<Pill bg="var(--mantine-color-red-1)" mb={6}>
										<span>{tagText}</span>
									</Pill>
								)}
								<Text size="sm">{inspection.station.name}</Text>
							</>
						);
					},
					filter: (
						<Stack>
							{problemFilter}
							{stationFilter}
						</Stack>
					),
					filtering:
						stationFilterValue.length > 0 || problemFilterValue.length > 0,
					width: 150,
				},
			]
		: [
				{
					accessor: "station.name",
					title: "Standort",
					filter: stationFilter,
					filtering: stationFilterValue.length > 0,
				},
				{
					accessor: "issues",
					title: "Probleme",
					render: (inspection) => (inspection.issues.length ? "ja" : "nein"),
					filter: problemFilter,
					filtering: problemFilterValue.length > 0,
				},
				{
					accessor: "issues.length",
					title: "Problemanzahl",
					render: (inspection) => <Box mx={40}>{inspection.issues.length}</Box>,
				},
			];

	columns.push({
		accessor: "date",
		title: "Datum",
		render: (inspection) =>
			humanizedDateTimeFormat(new Date(inspection.createdAt)),
		filter: dateFilter,
		filtering: Boolean(dateFilterValue),
	});

	if (isRangerOrHigher(userRoles)) {
		columns.push({
			accessor: "actions",
			title: "Aktion",
			render: (inspection) => (
				<ActionIcon
					variant="transparent"
					onClick={(event) => {
						event.stopPropagation();
						confirmDeleteInspection(isMobile, inspection.id, queryClient);
					}}
				>
					<IconTrashX />
				</ActionIcon>
			),
			width: "0%",
		});
	}

	return columns;
};
