/* eslint-disable react/no-unstable-nested-components */
import React, { ReactElement, useEffect, useState } from "react";
import { subYears } from "date-fns";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ComboboxData, LoadingOverlay, Modal } from "@mantine/core";
import { validate as isValidUUID } from "uuid";
import { useDisclosure } from "@mantine/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { DateValue } from "@mantine/dates";
import inspectionService from "../../../services/inspectionService";
import { Inspection } from "../../../utilities/api/jelbi-dashboard-api";
import { QUERY_KEY_ALL_INSPECTIONS } from "../../../utilities/client/query-keys";
import useIsMobileView from "../../../utilities/client/hooks/useIsMobileView";
import useGetUserRoles from "../../../utilities/client/hooks/useGetUserRoles";
import Error from "../../Error/Error";
import Table from "../../Table/Table";
import ReadInspection from "../ReadInspection/ReadInspection";
import {
	filterRecords,
	getColumnConfig,
} from "./utils/InspectionsTable.tableHelper";

function InspectionsTable(): ReactElement {
	const isMobile = useIsMobileView();
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const userRoles = useGetUserRoles();

	const [stationForSelectData, setStationForSelectData] =
		useState<ComboboxData>([]);
	const [records, setRecords] = useState<Inspection[]>([]);
	const [stationFilterValue, setStationFilterValue] = useState<string[]>([]);
	const [problemFilterValue, setProblemFilterValue] = useState<string[]>([]);
	const [dateFilterValue, setDateFilterValue] = useState<DateValue>();
	const [page, setPage] = useState<number>(1);

	const { inspectionId: shownInspectionId } = useParams();
	const [
		isInspectionModalShown,
		{ open: showInspectionModal, close: closeInspectionModal },
	] = useDisclosure(false);

	const {
		data: inspections,
		isPending,
		isError,
	} = useQuery({
		queryKey: [QUERY_KEY_ALL_INSPECTIONS],
		queryFn: () => {
			const endDate = new Date().toISOString();
			const startDate = subYears(endDate, 1).toISOString();
			return inspectionService.fetchInspections(startDate, endDate);
		},
	});

	const columns = getColumnConfig(
		isMobile,
		stationForSelectData,
		problemFilterValue,
		setProblemFilterValue,
		stationFilterValue,
		setStationFilterValue,
		dateFilterValue,
		setDateFilterValue,
		queryClient,
		userRoles
	);

	useEffect(() => {
		if (shownInspectionId) {
			if (isValidUUID(shownInspectionId)) {
				showInspectionModal();
			} else {
				navigate("/inspections");
			}
		}
	}, []);

	useEffect(() => {
		if (inspections) {
			const filteredRecords = filterRecords(
				inspections.results,
				stationFilterValue,
				problemFilterValue,
				dateFilterValue
			);
			setRecords(filteredRecords);
			setPage(1);
		}
	}, [stationFilterValue, problemFilterValue, dateFilterValue]);

	useEffect(() => {
		if (inspections) {
			setStationForSelectData([
				...new Set(
					inspections.results.map((inspection) => inspection.station.name)
				),
			]);

			const filteredRecords = filterRecords(
				inspections.results,
				stationFilterValue,
				problemFilterValue,
				dateFilterValue
			);
			setRecords(filteredRecords);
		}
	}, [inspections]);

	if (isPending) {
		return <LoadingOverlay visible />;
	}

	if (isError) {
		return <Error />;
	}

	return (
		<>
			<Table<Inspection>
				columns={columns}
				records={records}
				onRowClick={({ record }) => {
					navigate(`/inspections/${record.id}`);
					showInspectionModal();
				}}
				page={page}
				setPage={setPage}
			/>
			<Modal
				fullScreen
				withCloseButton={false}
				opened={isInspectionModalShown}
				onClose={() => {
					closeInspectionModal();
					navigate(`/inspections`);
				}}
			>
				<ReadInspection
					onClose={() => {
						closeInspectionModal();
						navigate(`/inspections`);
					}}
				/>
			</Modal>
		</>
	);
}

export default InspectionsTable;
